import { jsPDF } from "jspdf";
import formatDateBasedTimezoneValue from "@/app/infrastructures/misc/common-library/FormatDateBasedTimezoneValue";
import JsBarcode from "jsbarcode";
import { OptionsClass } from "@/domain/entities/MainApp";
import { DeliveryManifestPdfData } from "@/domain/entities/DeliveryManifest";
import { formatInputFloat } from "@/app/infrastructures/misc/Utils";

const margin = {
  left: 8,
  right: 8,
  top: 8,
  bottom: 8
};
let doc: any = new jsPDF("p", "mm", "a6", true),
  logo = "",
  xx = margin.left,
  y = margin.top,
  barcodeValue = "";

const x = margin.left,
  totalWidthPage = doc.internal.pageSize.width,
  maxRight = totalWidthPage - margin.right,
  logoWidth = 26,
  barcodeId = "barcode";

const onReset = () => {
  logo = "";
  xx = margin.left;
  y = margin.top;
  barcodeValue = "";
};

const generateLabelDeliveryManifest = async (
  params: DeliveryManifestPdfData
) => {
  doc = new jsPDF("p", "mm", "a6", true);

  // logo lion parcel
  const importLogo = await import(
    `@/app/ui/assets/images/logo-lion-parcel.png`
  );
  logo = importLogo.default;
  const logoProperties = doc.getImageProperties(logo);
  const logoHeight = (logoProperties.height / logoProperties.width) * logoWidth;
  doc.addImage(
    logo,
    "PNG",
    x,
    y,
    logoWidth,
    logoHeight,
    "logo-lion-parcel",
    "FAST"
  );

  // date
  y += 4;
  const date = formatDateBasedTimezoneValue(params.deliveryManifestCreatedAt, "DD MMMM YYYY, HH:mm:ss");
  doc.setFontSize(9);
  doc.setTextColor("#4d4d4d");
  doc.setFont("Poppins", "medium");
  doc.text(date, maxRight - doc.getTextWidth(date), y);

  // horizontal line
  y += 7;
  doc.setDrawColor("#E0E0E0");
  doc.setLineWidth(0.5);
  doc.line(x, y, maxRight, y);

  // generate barcode
  document.getElementById(barcodeId)?.remove();
  if (params.deliveryManifestNo) {
    const barcodeImg = document.createElement("img");
    barcodeImg.setAttribute("id", barcodeId);
    barcodeImg.setAttribute("style", "display: none;");
    barcodeImg.setAttribute("alt", "barcode");
    JsBarcode(barcodeImg, params.deliveryManifestNo, { displayValue: false });
    document.body.appendChild(barcodeImg);
    barcodeValue =
      document.getElementById("barcode")?.getAttribute("src") || "";
  }
  y += 5;
  doc.addImage(
    barcodeValue,
    "PNG",
    x - 3,
    y,
    totalWidthPage - 10,
    22,
    "barcode",
    "FAST"
  );

  y += 30;
  doc.setFontSize(20);
  doc.setFont("Poppins", "bold");
  doc.text(params.deliveryManifestNo, totalWidthPage / 2, y, "center");

  // horizontal line
  y += 10;
  doc.line(x, y, maxRight, y);

  const contentData = [
    // new OptionsClass({
    //   name: "Ursacode",
    //   value: params.deliveryManifestUrsaCode
    // }),
    new OptionsClass({
      name: "Total STT",
      value: `${formatInputFloat(params.deliveryManifestTotalStt)}`
    }),
    new OptionsClass({
      name: "Total Koli",
      value: `${formatInputFloat(params.deliveryManifestTotalSttPiece)}`
    }),
    new OptionsClass({
      name: "Total Gross Weight",
      value: `${formatInputFloat(params.deliveryManifestTotalGrossWeight)} Kg`
    })
  ];

  y += 14;
  xx += 12;
  for (const item of contentData) {
    doc.setFontSize(12);
    doc.setFont("Poppins", "normal");
    doc.text(item.name, xx, y);
    doc.setFont("Poppins", "semibold");
    doc.text(` : ${item.value}`, xx + 46, y);
    y += 8;
  }

  // horizontal line
  y += 6;
  doc.line(x, y, maxRight, y);

  // footer
  y += 12;
  doc.setFontSize(8);
  doc.setFont("Poppins", "normal");
  doc.text(
    "Terjadi kendala pada proses pengiriman?\nHubungi Customer Service 021-80820072 atau hubungi Kota\nAsal yang bersangkutan.",
    totalWidthPage / 2,
    y,
    { align: "center", lineHeightFactor: 1.5 }
  );
  doc.setProperties({
    title: `Delivery Manifest Label.pdf`
  });

  doc.autoPrint();

  window.open(doc.output("bloburl"), "_blank");
  onReset();
};

export default generateLabelDeliveryManifest;


import { Vue, Options } from "vue-class-component";
import UpdateNotification from "@/app/ui/views/incoming-process/sti-dest/component/update-notification.vue";
import {
  checkRolePermission,
  convertDecimalWithComma,
  formatDateWithoutTime
} from "@/app/infrastructures/misc/Utils";
import { DeliveryManifestController } from "@/app/ui/controllers/DeliveryManifestController";
import { INCOMING_PROCESS_DELIVERY_MANIFEST } from "@/app/infrastructures/misc/RolePermission";
import EmptyState from "@/app/ui/components/empty-list/index.vue";
import {
  DeliveryManifestData
} from "@/domain/entities/DeliveryManifest";
import { onPrintDeliveryManifest } from "./modules/manifest";

@Options({
  components: {
    UpdateNotification,
    EmptyState
  },
  computed: {
    getTitle() {
      return "Delivery Manifest";
    },
    ableToCreate() {
      return checkRolePermission(INCOMING_PROCESS_DELIVERY_MANIFEST.CREATE);
    }
  }
})
export default class DeliveryManifest extends Vue {
  created() {
    DeliveryManifestController.setErrorPrint(false);
    this.refs = this.$refs;
    DeliveryManifestController.setSearch("");
  }

  refs: any = "";

  fetchDeliveryManifestList() {
    DeliveryManifestController.fetchDeliveryManifestList();
  }

  // loading
  get isLoading() {
    return DeliveryManifestController.isLoading;
  }

  // error cause
  get errorCause() {
    return DeliveryManifestController.errorCause;
  }

  // filter date
  get startDate() {
    return DeliveryManifestController.filter.startDate;
  }

  get endDate() {
    return DeliveryManifestController.filter.endDate;
  }

  setDateRange(value: Array<any>) {
    DeliveryManifestController.setStartDate(value[0]);
    DeliveryManifestController.setEndDate(value[1]);
    value[0] && value[1] && this.fetchDeliveryManifestList();
  }

  // go to update delivery
  goToUpdate() {
    this.$router.push("/delivery-manifest/update");
  }

  // Table
  get columns() {
    return [
      {
        name: "No.",
        styleHead: "w-1/20 text-left whitespace-no-wrap",
        render: (item: DeliveryManifestData, index: number) => {
          return `<div class="overflow-ellipsis text-left">${index + 1}</div>`;
        }
      },
      {
        name: "No. Delivery Manifest",
        styleHead: "w-1/5 text-left whitespace-no-wrap",
        render: (item: DeliveryManifestData) => {
          return `<div class="overflow-ellipsis text-left">${item.deliveryManifestNo}</div>`;
        }
      },
      {
        name: "Total STT",
        styleHead: "w-2/20 text-left whitespace-no-wrap",
        render: (item: DeliveryManifestData) => {
          return `<div class="overflow-ellipsis text-left">${item.deliveryManifestTotalStt}</div>`;
        }
      },
      {
        name: "Total Koli",
        styleHead: "w-2/20 text-left whitespace-no-wrap",
        render: (item: DeliveryManifestData) => {
          return `<div class="overflow-ellipsis text-left">${item.deliveryManifestTotalPiece}</div>`;
        }
      },
      {
        name: "Total Berat Kotor",
        styleHead: "w-3/20 text-left whitespace-no-wrap",
        render: (item: DeliveryManifestData) => {
          return `<div class="overflow-ellipsis text-left">${convertDecimalWithComma(
            item.deliveryManifestTotalGrossWeight,
            2
          )} Kg</div>`;
        }
      },
      {
        name: "Total Berat Dimensi",
        styleHead: "w-3/20 text-left whitespace-no-wrap",
        render: (item: DeliveryManifestData) => {
          return `<div class="overflow-ellipsis text-left">${convertDecimalWithComma(
            item.deliveryManifestTotalVolumeWeight,
            2
          )} Kg</div>`;
        }
      },
      {
        name: "Tanggal Dibuat",
        styleHead: "w-3/20 text-left whitespace-no-wrap",
        render: (item: DeliveryManifestData) => {
          return `<div class="text-black-lp-300">${formatDateWithoutTime(
            item.deliveryManifestCreatedAt
          )}</div>
            <div class="capitalize text-gray-lp-500">${
              item.deliveryManifestCreatedBy
            }</div>`;
        }
      },
      {
        name: "Action",
        key: "button_column",
        styleHead: "w-2/20 text-left",
        styleButton: (item: DeliveryManifestData) => {
          return {
            outline: true,
            icon: "option-gray",
            title: "",
            textColor: "black",
            color: "gray-lp-400",
            customClass: "button-option-sti",
            clickFunction: () => null,
            groupOptions: [
              {
                icon: "printer",
                label: "Print Manifest",
                clickFunction: () =>
                  onPrintDeliveryManifest(item.deliveryManifestId, "manifest")
              },
              {
                icon: "invoice",
                label: "Print Label",
                clickFunction: () =>
                  onPrintDeliveryManifest(item.deliveryManifestId, "label")
              }
            ]
          };
        }
      }
    ];
  }
  idToPrint = 0;

  get isErrorPrint() {
    return DeliveryManifestController.isErrorPrint;
  }

  get isLoadingPrint() {
    return DeliveryManifestController.isLoadingPrint;
  }

  closeModalErrorPrint() {
    DeliveryManifestController.setErrorPrint(false);
    DeliveryManifestController.setErrorCause("");
  }

  get pagination() {
    return DeliveryManifestController.deliveryManifestList.pagination;
  }

  get deliveryManifestList() {
    return DeliveryManifestController.deliveryManifestList.data;
  }

  onSearch(value: string) {
    DeliveryManifestController.setFirstPage();
    DeliveryManifestController.setSearch(value);
    this.fetchDeliveryManifestList();
  }

  get searchValue() {
    return DeliveryManifestController.filter.search
  }
  
  clearSearch() {
    this.onSearch("");
  }
}

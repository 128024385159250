import { DeliveryManifestController } from "@/app/ui/controllers/DeliveryManifestController";
import { generateManifest2ColumnLandscape } from "@/app/ui/modules/manifest-v2/landscape";
import {
  DeliveryManifestPdfData,
  DeliveryManifestPdfStt
} from "@/domain/entities/DeliveryManifest";
import {
  ColumnManifestData,
  HeaderManifestData,
  OptionsClass,
  SectionManifestData
} from "@/domain/entities/MainApp";
import generateLabelDeliveryManifest from "./label";
import { MainAppController } from "@/app/ui/controllers/MainAppController";

export const onPrintDeliveryManifest = async (
  id: number,
  type: "manifest" | "label"
) => {
  MainAppController.showLoading();
  const detailData: DeliveryManifestPdfData = await DeliveryManifestController.getDeliveryManifestPdfData(
    id
  );
  switch (type) {
    case "manifest":
      generateManifest2ColumnLandscape(
        new SectionManifestData({
          featureName: "Delivery",
          headerSection: [
            "destinationCity",
            "createdBy",
            "key",
            "createdAt",
            "totalStt",
            "totalGrossWeight",
            "totalVolumeWeight"
            // "ursaCode"
          ],
          headerDetailData: new HeaderManifestData({
            destinationCity: `${detailData.deliveryManifestDestinationCityId} - ${detailData.deliveryManifestDestinationCityName}`,
            createdAt: detailData.deliveryManifestCreatedAt,
            createdBy: detailData.deliveryManifestCreatedName,
            key: new OptionsClass({
              name: "No. Delivery Manifest",
              value: detailData.deliveryManifestNo
            }),
            totalStt: detailData.deliveryManifestTotalStt,
            totalGrossWeight: detailData.deliveryManifestTotalGrossWeight,
            totalVolumeWeight: detailData.deliveryManifestTotalVolumeWeight,
            qrCodeValue: `${detailData.deliveryManifestNo}#${
              detailData.deliveryManifestTotalStt
            }#${detailData.deliveryManifestTotalSttPiece}#${
              detailData.deliveryManifestTotalGrossWeight
            }#${
              detailData.deliveryManifestTotalVolumeWeight
            }#${detailData.deliveryManifestCreatedAt.substring(0, 10)}#${
              detailData.deliveryManifestCreatedName
            }`,
            ursaCode: detailData.deliveryManifestUrsaCode,
            isFormatDateUsingTimestamp: true
          }),
          columns: ["sttNo", "receiver", "codFee", "signature"],
          data: detailData.stt.map(
            (el: DeliveryManifestPdfStt) =>
              new ColumnManifestData({
                sttNo: el.sttNo,
                productType: el.sttProductType,
                totalPiece: el.sttTotalPiece,
                receiver: `${el.sttRecipientName} +${el.sttRecipientPhone}\n${el.sttRecipientAddress}`,
                codFee: el.sttCodAmount
              })
          ),
          signature: true
        })
      );
      break;

    case "label":
      generateLabelDeliveryManifest(detailData);
      break;
  }
  MainAppController.closeLoading();
};

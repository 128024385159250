import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "bg-white flex flex-col text-12px xxl:text-14px overflow-hidden h-screen relative w-full" }
const _hoisted_2 = { class: "flex gap-x-4" }
const _hoisted_3 = { class: "font-semibold self-center mr-10 text-20px whitespace-no-wrap text-black-lp-200 capitalize" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Search = _resolveComponent("Search")!
  const _component_time_range_picker = _resolveComponent("time-range-picker")!
  const _component_lp_button = _resolveComponent("lp-button")!
  const _component_Header = _resolveComponent("Header")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_TableV2 = _resolveComponent("TableV2")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_Header, { class: "items-center" }, {
        default: _withCtx(() => [
          _createVNode("div", _hoisted_2, [
            _createVNode("div", _hoisted_3, _toDisplayString(_ctx.getTitle), 1),
            _createVNode(_component_Search, {
              onInput: _ctx.onSearch,
              onClear: _ctx.clearSearch,
              value: _ctx.searchValue,
              class: "w-300px",
              placeholder: "Cari No. Manifest/No. STT"
            }, null, 8, ["onInput", "onClear", "value"]),
            _createVNode(_component_time_range_picker, {
              onSelect: _ctx.setDateRange,
              default: "7 hari terakhir"
            }, null, 8, ["onSelect"])
          ]),
          (_ctx.ableToCreate)
            ? (_openBlock(), _createBlock(_component_lp_button, {
                key: 0,
                onClick: _ctx.goToUpdate,
                title: "Buat Manifest",
                textColor: "white"
              }, null, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      (
        _ctx.deliveryManifestList.length === 0 &&
          !_ctx.isLoading &&
          !_ctx.startDate &&
          !_ctx.endDate
      )
        ? (_openBlock(), _createBlock(_component_EmptyState, {
            key: 0,
            data: "Delivery Manifest"
          }))
        : (_openBlock(), _createBlock(_component_TableV2, {
            key: 1,
            columns: _ctx.columns,
            data: _ctx.deliveryManifestList,
            loading: _ctx.isLoading,
            pagination: _ctx.pagination,
            "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => (_ctx.pagination = $event)),
            onRequest: _ctx.fetchDeliveryManifestList,
            errorCause: _ctx.errorCause
          }, null, 8, ["columns", "data", "loading", "pagination", "onRequest", "errorCause"]))
    ]),
    (_ctx.isErrorPrint && !_ctx.isLoadingPrint)
      ? (_openBlock(), _createBlock(_component_ErrorMessage, {
          key: 0,
          errorType: _ctx.errorCause,
          onCancel: _ctx.closeModalErrorPrint,
          onTryAgain: () => _ctx.refs.print.printManifest(_ctx.idToPrint),
          modal: ""
        }, null, 8, ["errorType", "onCancel", "onTryAgain"]))
      : _createCommentVNode("", true)
  ], 64))
}
/* eslint-disable @typescript-eslint/camelcase */

import { UpdateDeliveryInterface } from "../contracts/DeliveryRequest";

export class DeliveryApiRequest implements UpdateDeliveryInterface {
  private destination_city_id: string;
  private stt_no: Array<string>;

  constructor(destination_city_id: string, stt_no: Array<string>) {
    this.destination_city_id = destination_city_id;
    this.stt_no = stt_no;
  }

  public toJSON(): string {
    return JSON.stringify({
      destination_city_id: this.destination_city_id,
      stt_no: this.stt_no
    });
  }
}

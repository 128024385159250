import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { Pagination } from "@/domain/entities/Pagination";
import {
  DeliveryManifestEntities,
  DeliveryManifestPdfData,
  DetailSttDeliveryManifest
} from "@/domain/entities/DeliveryManifest";
import { container } from "tsyringe";
import { DeliveryManifestPresenter } from "@/app/ui/presenters/DeliveryManifestPresenter";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { DeliveryApiRequest } from "@/data/payload/api/DeliveryApiRequest";
import { MainAppController } from "./MainAppController";
import { CLIENT_ERROR, parsingErrorResponse } from "@/app/infrastructures/misc/Utils";
import { playNotification } from "@/app/infrastructures/misc/UtilsAudio";
import { ErrorMessageEntities } from "@/domain/entities/MainApp";

export interface DeliveryManifestState {
  isLoading: boolean;
  isError: boolean;
  errorCause: string;
  deliveryManifestList: DeliveryManifestEntities;
}

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "incoming-delivery-manifest"
})
class DeliveryManifestStore extends VuexModule
  implements DeliveryManifestState {
  public isOpenSuccess = false;
  public isFailed = false;
  public isLoading = false;
  public isLoadingUpdate = false;
  public errorCauseUpdate = "";
  public sttFailedUpdate: Array<string> = [];
  public errorUpdate = false;
  public isError = false;
  public errorCause = "";
  public isErrorPrint = false;
  public isLoadingPrint = false;
  public filter = {
    startDate: "",
    endDate: "",
    search: ""
  };
  public respPayload = new ResponsePayload();
  public deliveryManifestList = new DeliveryManifestEntities(
    new Pagination(1, 10),
    []
  );

  @Action
  getDeliveryManifestList(params: {
    page: number;
    limit: number;
    search: string;
    startDate: string;
    endDate: string;
  }) {
    this.setIsLoading(true);
    const presenter = container.resolve(DeliveryManifestPresenter);
    return presenter
      .getDeliveryManifestList(
        params.page,
        params.limit,
        params.startDate,
        params.endDate,
        params.search
      )
      .then((res: DeliveryManifestEntities) => {
        this.setDeliveryManifest(res);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((err: any) => {
        this.setError(true);
        this.setErrorCause(err.response ? "server" : "internet");
      })
      .finally(() => this.setIsLoading(false));
  }

  @Action
  public fetchDeliveryManifestList() {
    this.getDeliveryManifestList({
      page: this.deliveryManifestList.pagination.page,
      limit: this.deliveryManifestList.pagination.limit,
      search: this.filter.search,
      startDate: new Date(this.filter.startDate).toLocaleDateString("fr-CA"),
      endDate: new Date(this.filter.endDate).toLocaleDateString("fr-CA")
    });
  }

  @Action
  public getDetailDeliveryStt(params: {
    sttOrBagNumber: string;
    callback: any;
    noRef?: string;
  }) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(DeliveryManifestPresenter);
    return presenter
      .getDetailDeliveryStt(params.sttOrBagNumber)
      .then((res: DetailSttDeliveryManifest[]) => {
        params.callback(res[0], params.noRef);
      })
      .catch((err: any) => {
        const errorMessage = err.response.data.message.id;
        const isJumbopackH2H = errorMessage === "STT gagal discan karena paket akan diberi status POD setelah diambil oleh penerima.";
        playNotification("error");
        if (isJumbopackH2H) {
          MainAppController.showErrorMessage(
            new ErrorMessageEntities({
              type: CLIENT_ERROR,
              title: "Paket akan diambil penerima",
              message: errorMessage,
              onClose: () => params.callback('error', params.noRef),
              buttonSuccessText: "Oke, mengerti"
            })
          );
        }
        else {
          MainAppController.showErrorMessage(
            parsingErrorResponse(err, "Tambah No. STT Gagal!", () =>
              this.getDetailDeliveryStt(params)
            )
          );
        }
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  public getDeliveryManifestPdfData(id: number) {
    this.setLoadingPrint(true);
    const presenter = container.resolve(DeliveryManifestPresenter);
    return presenter
      .getDeliveryManifestPdfData(id)
      .then(res => res)
      .catch(err => {
        this.setErrorPrint(true);
        this.setErrorCause(err.response ? "server" : "internet");
        return new DeliveryManifestPdfData();
      })
      .finally(() => this.setLoadingPrint(false));
  }

  @Mutation
  public setErrorPrint(val: boolean) {
    this.isErrorPrint = val;
  }

  @Mutation
  public setLoadingPrint(val: boolean) {
    this.isLoadingPrint = val;
  }

  @Action
  public updateDelivery(params: {
    destinationCityId: string;
    sttNo: Array<string>;
  }) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(DeliveryManifestPresenter);
    return presenter
      .updateDelivery(
        new DeliveryApiRequest(params.destinationCityId, params.sttNo)
      )
      .then((res: ResponsePayload) => {
        this.setRespUpdateDelivery(res);
        return res;
      })
      .catch((err: any) => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Perubahan Vendor Gagal!", () =>
            this.updateDelivery(params)
          )
        );
        return new ResponsePayload();
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Mutation
  private setDeliveryManifest(data: DeliveryManifestEntities) {
    this.deliveryManifestList = data;
  }

  @Mutation
  public setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  @Mutation
  public setSttFailedUpdate(value: Array<string>) {
    this.sttFailedUpdate = value;
  }

  @Mutation
  public setLoadingUpdate(val: boolean) {
    this.isLoadingUpdate = val;
  }

  @Mutation
  public setRespUpdateDelivery(val: ResponsePayload) {
    this.respPayload = val;
  }

  @Mutation
  public setErrorUpdate(val: boolean) {
    this.errorUpdate = val;
  }

  @Mutation
  public setErrorCauseUpdate(val: string) {
    this.errorCauseUpdate = val;
  }

  @Mutation
  public setSearch(value: string) {
    this.filter.search = value;
  }

  @Mutation
  public setStartDate(value: any) {
    this.filter.startDate = value;
  }

  @Mutation
  public setEndDate(value: any) {
    this.filter.endDate = value;
  }

  @Mutation
  public setError(value: boolean) {
    this.isError = value;
  }

  @Mutation
  public setErrorCause(value: string) {
    this.errorCause = value;
  }

  @Mutation
  public setOpenSuccess(val: boolean) {
    this.isOpenSuccess = val;
  }

  @Mutation
  public setFailed(val: boolean) {
    this.isFailed = val;
  }

  @Mutation
  public setFirstPage() { 
    this.deliveryManifestList.pagination.page = 1;
  }
}

export const DeliveryManifestController = getModule(DeliveryManifestStore);
